import { object, string, array, boolean, number } from 'yup';
import {
  CONSTANT,
  VALIDATIONS,
  generalStatus,
  planFeatures,
  subscriptionPlanType
} from '../config/constant';

const atLeastOneTrueValidator = function (array) {
  return array.some((obj) => obj.status === true);
};

const validationSchema = object({
  name: string()
    .min(CONSTANT.MIN_NAME_LENGTH, VALIDATIONS.MIN_PLAN_NAME)
    .max(CONSTANT.MAX_NAME_LENGTH, VALIDATIONS.MAX_PLAN_NAME)
    .required(VALIDATIONS.PLAN_REQUIRED)
    .trim(),
  type: string().required(VALIDATIONS.PLAN_TYPE_REQUIRED),
  price: string().when('type', {
    is: (type) => type === subscriptionPlanType['Paid'],
    then: () => string().min(1, VALIDATIONS.MIN_PLAN_PRICE).required(VALIDATIONS.PRICE_REQUIRED),
    otherwise: () => string().min(0).optional()
  }),
  tax_percentage: number().when('type', {
    is: (type) =>
      type === subscriptionPlanType['Enterprise'] || type === subscriptionPlanType['Paid'],
    then: (schema) =>
      schema
        .min(1, VALIDATIONS.MIN_PLAN_TAX)
        .max(CONSTANT.MAX_TAX, VALIDATIONS.MAX_PLAN_TAX)
        .required(VALIDATIONS.TAX_PERCENTAGE_REQUIRED),
    otherwise: (schema) => schema.min(0).optional()
  }),
  features: array()
    .of(
      object().shape({
        status: boolean().required()
      })
    )
    .test('at-least-one-true', VALIDATIONS.FEATURES_REQUIRED, atLeastOneTrueValidator),
  currency: string().required(VALIDATIONS.CURRENCY_REQUIRED),
  status: string().optional()
});

export const initialValues = {
  name: '',
  type: '',
  price: '0',
  tax_percentage: '0',
  features: planFeatures,
  currency: '',
  status: generalStatus['Active']
};
export default validationSchema;
