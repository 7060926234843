export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  FORGET_PASSWORD: '/forgot-password',
  CHECK_MAIL: '/checkmail',
  RESET_PASSWORD: '/reset-password/:token',
  PAGE_404: '/404',
  PAGE_500: '/500'
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
  PROFILE: '/my-profile',
  PROJECT_CATEGORY: '/project-category',
  PRODUCTION_HOUSE: '/production-house'
};
