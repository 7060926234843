import { object, string } from 'yup';
import { CONSTANT, REGEX, VALIDATIONS } from '../config/constant';

const validationSchema = object({
  name: string()
    .min(CONSTANT.MIN_NAME_LENGTH, VALIDATIONS.MIN_CATEGORY_NAME)
    .max(CONSTANT.MAX_NAME_LENGTH, VALIDATIONS.MAX_CATEGORY_NAME)
    .required(VALIDATIONS.PROJECT_CATEGORY_REQUIRED)
    .matches(REGEX.ALPHA_NUMERIC_REGEX, VALIDATIONS.INVALID_CATEGORY)
    .trim()
});

export const initialValues = {
  name: ''
};
export default validationSchema;
