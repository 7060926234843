import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/Api
 */

function* loadFromApi(payload) {
  try {
    yield put(actions.loadFromApiSuccess(payload.data));
  } catch (error) {
    yield put(actions.loadFromApiError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_FROM_API, loadFromApi)]);
}
