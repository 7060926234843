const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  UPDATE_USER_DETAIL: 'UPDATE_USER_DETAIL',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token, user) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token, user }
  }),
  logout: () => ({
    type: actions.LOGOUT
  }),
  updateUserDetails: (user) => ({
    type: actions.UPDATE_USER_DETAIL,
    payload: { user }
  })
};
export default actions;
