import { object, string } from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

const validationSchema = object({
  name: string()
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be less than 50 characters')
    .trim()
    .required('First name is required'),
  display_name: string()
    .trim()
    .min(3, 'Display Name must be at least 3 characters')
    .max(50, 'Display Name must be less than 50 characters')
    .required('Display name is required'),
  email: string()
    .email('Enter valid email')
    .max(64, 'Email must be at most 64 characters')
    .required('Email is required'),
  mobile_number: string()
    .required('Phone is required')
    .test('validate-phone', 'Phone is invalid', (value) => isValidPhoneNumber(value)),
  status: string().trim().required('Status is required')
});

export const initialValues = {
  name: '',
  display_name: '',
  email: '',
  mobile_number: '',
  status: ''
};
export default validationSchema;
