import actions from './actions';

const initState = { token: null, user: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        token: action.token,
        user: action.user
      };
    case actions.LOGOUT:
      return initState;
    case actions.UPDATE_USER_DETAIL:
      return {
        ...state,
        user: action.payload.user
      };

    default:
      return state;
  }
}
